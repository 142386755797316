import styled from "styled-components";
import React, { Component } from "react";
import { CONTENT_HEIGHT, CONTENT_WIDTH } from "./Utils";
import Label from "./Label";

const StyledContent = styled.div`
  background-color: ${({ selected }) => selected ?  "rgba(0, 0, 0, 0.05)" : "#FFF"};
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  flex: 0 1 auto;
  height: ${CONTENT_HEIGHT}px;
  width: ${CONTENT_WIDTH}px;
  border-radius: 2px;
  padding: 2px;
  margin-bottom: 3px;
  box-sizing: border-box;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const Title = styled.div`
  display: flex;
  flex: 2 1 auto;
  text-align: left;
  height: 70px;
  width: 166px;
  color: #14285a;
  font-family: BrownPro;
  font-size: 13px;
  align-items: center;
  line-height: 18px;
  margin-left: 10px;
`;

const Image = styled.img`
  height: 70px;
  width: 96px;
`;

class Content extends Component {
  handleClick = () => {
    const { onClick, url, contentType, showInLightbox, id } = this.props;
    onClick({ contentType, url, showInLightbox, id });
  };

  render() {
    const {
      thumbnailUrls,
      title,
      url,
      label,
      id,
      currentAsset
    } = this.props;

    return (
      <StyledContent
        onClick={this.handleClick}
        selected={currentAsset === id}
      >
        {label && (
          <Label name={label}/>
        )}
        <Image src={thumbnailUrls} alt={url} />
        <Title>{title}</Title>
      </StyledContent>
    );
  }
}

export default Content;
