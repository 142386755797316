export const BOTTOM = "bottom";
export const FLOAT = "float";
const BOTTOM_SIDEBAR_HEIGHT = 20;
const BOTTOM_CONTAINER_HEIGHT = 375;
const FLOAT_SIDEBAR_WIDTH = 50;
export const FLOAT_CONTAINER_WIDTH = 300;
export const FLOAT_CONTAINER_HEIGHT = 375;
export const CONTENT_HEIGHT = 78;
export const CONTENT_WIDTH = 280;
export const MAIN_CONTENT_MARGIN = 10;
export const Dimensions = {
  bottom: {
    sidebar: { width: "100%", height: `${BOTTOM_SIDEBAR_HEIGHT}px` },
    contentContainer: { width: "100%", height: `${BOTTOM_CONTAINER_HEIGHT}px` },
    promoterContainer: {
      width: "100%",
      height: `${BOTTOM_SIDEBAR_HEIGHT + BOTTOM_CONTAINER_HEIGHT}px`
    }
  },
  float: {
    sidebar: { width: `${FLOAT_SIDEBAR_WIDTH}px`, height: "100%" },
    contentContainer: { height: `${CONTENT_HEIGHT*4}px` },
    promoterContainer: {
      height: `${FLOAT_CONTAINER_HEIGHT}px`
    }
  }
};

export const FlowDirections = {
  bottom: { contentContainer: "row", promoterContainer: "column" },
  float: { contentContainer: "column", promoterContainer: "row" }
};

export const LABELS = {
  "Featured": "#001EAA",
  "Resume": "#797979",
  "Trending": "#1F8972"
};
