import React, { Fragment } from "react";
import styled from "styled-components";
import ReactModal from "react-modal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const HtmlLightbox = ({ src }) => (
  <iframe
    title="dragonboat-html-lightbox"
    src={src}
    style={{
      width: "100%",
      height: "100%",
      position: "absolute",
      left: 0,
      right: 0,
      margin: "auto",
      top: "50%",
      transform: "translateY(-50%)",
      border: 0
    }}
  />
);

const reactModalStyle = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: 999,
  },
  content: {
    position: "absolute",
    top: "80px",
    left: "80px",
    right: "80px",
    bottom: "80px",
    zIndex: 999,
    border: 0,
    backgroundColor: null
  }
};

const CloseIconBox = styled.div`
  width: 45px;
  height: 45px;
  background-color: #2a2a2a;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  cursor: pointer;
  flex: 0 1 auto;
  align-items: center;
  justify-content: center;
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
`;

const CloseIcon = styled(FontAwesomeIcon)`
  font-size: 30px;
  z-index: 1000;
  color: #FFFFFF;
`;

const Lightbox = ({ url, contentType, isOpen, onClose }) => {
  if (url === null || url === "") {
    return <noscript />;
  }

  return (
    <Fragment>
      <CloseIconBox isOpen={isOpen} onClick={onClose}>
        <CloseIcon icon={faTimes} />
      </CloseIconBox>
      <ReactModal
        style={reactModalStyle}
        parentSelector={() => document.getElementById("root")}
        isOpen={isOpen}
        shouldCloseOnEsc={true}
        onRequestClose={onClose}
        ariaHideApp={false}
      >
        <HtmlLightbox src={url} />
      </ReactModal>
    </Fragment>
  );
};
export default Lightbox;
