import React from "react";
import styled from "styled-components";
import { FlowDirections, Dimensions } from "./Utils";
import Content from "./Content";

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: ${props => FlowDirections[props.position].contentContainer}
  flex: 0 1 auto;
  ${props => Dimensions[props.position].contentContainer}
`;

const ContentContainer = ({ position, onContentSelect, content, currentAsset}) => (
  <StyledContentContainer position={position}>
    {content.map((elem, index) => (
      <Content
        key={index}
        id={elem.id}
        thumbnailUrls={elem.thumbnail}
        url={elem.url}
        title={elem.title}
        contentType={elem.contentType}
        label={elem.label}
        onClick={onContentSelect}
        showInLightbox={elem.showInLightbox}
        currentAsset={currentAsset}
      />
    ))}
  </StyledContentContainer>
);

export default ContentContainer;
