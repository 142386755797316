import React from "react";
import styled from "styled-components";
import { Dimensions, FLOAT_CONTAINER_HEIGHT } from "./Utils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';

const SidebarContainer = styled.div`
  background-color: #001EAA;
  cursor: pointer;
  display: block;
  position: relative;
  ${props => Dimensions[props.position].sidebar}
`;

const SidebarTextContainer = styled.div`
  position: absolute;
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  justify-content: center;
  width: ${FLOAT_CONTAINER_HEIGHT}px;
  height: 50px;
  color: #FFF;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 18px;
  transform: rotate(-90deg);
  bottom: -26px;
  left: 25px;
  transform-origin: left center 0;
  margin: 0;
`;

const SidebarIcon = styled(FontAwesomeIcon)`
  margin: 3px 0 0 7px;
`;

const SidebarText = styled.h3`
  display: inline-block;
  margin: 5px 0 0;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
`;

const Sidebar = ({ collapsed, position, onClick }) => {
  const arrowIcon = collapsed ? faAngleDown : faAngleUp;

  return (
    <SidebarContainer
      position={position}
      onClick={onClick}
    >
      <SidebarTextContainer>
        <SidebarText>Recommended For You</SidebarText>
        <SidebarIcon icon={arrowIcon} />
      </SidebarTextContainer>
    </SidebarContainer>
  );
}

export default Sidebar;
