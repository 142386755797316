import React, { Component, Fragment } from "react";
import styled from "styled-components";
import Sidebar from "./Sidebar";
import {
  FlowDirections,
  Dimensions,
  FLOAT_CONTAINER_WIDTH
} from "./Utils";

import ContentContainer from "./ContentContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import Lightbox from "./Lightbox";

const ContentPromoterContainer = styled.div`
  display: flex;
  flex-direction: ${props => FlowDirections[props.position].promoterContainer};
  background-color: #f7f7f7;
  position: fixed;
  box-shadow: -2px -2px 6px rgba(0, 0, 0, 0.2);
  bottom: 0px;
  right: ${({ collapsed }) => collapsed ? `-${FLOAT_CONTAINER_WIDTH}px` : "0px"};
  transition: right 0.2s ease-in-out;
  ${props => Dimensions[props.position].promoterContainer}
  z-index: 1000;
  margin-bottom: 61px;
`;

const DiscoverMoreIcon = styled(FontAwesomeIcon)`
  margin: 2px 0 0 7px;
`;

const DiscoverMore = styled.a`
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
  color: #14285a;
  font-family: BrownPro;
  font-size: 13px;
  line-height: 18px;
  cursor: pointer;
`;

const StyledMainSection = styled.div`
  padding: 10px;
  box-sizing: border-box;
  width: ${FLOAT_CONTAINER_WIDTH}px;
`;

class ContentPromoter extends Component {
  state = {
    contents: this.props.contents,
    collapsed: true,
    lightBox: { url: "", visible: false, contenType: null },
    currentAsset: this.props.currentAsset,
    timerId: null
  };

  handleSidebarClick = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  handleContentSelect = ({ contentType, url, showInLightbox, id }) => {
    const { contents, currentAsset } = this.state;
    if (!url) {
      return;
    } else if (showInLightbox) {
      this.setState({
        currentAsset: id,
        collapsed: true,
        lightBox: { url, contentType, visible: showInLightbox },
        contents: this.removeContent(contents, currentAsset)
      });
      this.openPromoterWithDelay();
    } else {
      window.location.href = url;
    }
  };

  handleOnCloseLightbox = () => {
    const { lightBox, contents, currentAsset } = this.state;
    this.setState({
      contents: this.removeContent(contents, currentAsset),
      lightBox: { ...lightBox, visible: false },
      currentAsset: null
    });
  };

  openPromoterWithDelay = () => {
    if(this.state.timerId) {
      clearTimeout(this.state.timerId);
    }

    const timerId = setTimeout(() => {
      if (this.state.collapsed) {
        this.setState({ 
          collapsed: false 
        });
      }
    }, 10000);

    this.setState({
      timerId: timerId
    });
  };

  removeContent(contents, currentAsset) {
    return contents.filter(content => content.id !== currentAsset);
  }

  componentDidMount = () => {
    this.openPromoterWithDelay();
  };

  render() {
    const { position } = this.props;
    const { contents, collapsed, lightBox } = this.state;

    const visibleContents = contents.slice(0, 4);

    return (
      <Fragment>
        <Lightbox
          url={lightBox.url}
          contentType={lightBox.contentType}
          isOpen={lightBox.visible}
          onClose={this.handleOnCloseLightbox}
        />

        <ContentPromoterContainer collapsed={collapsed} position={position}>
          <Sidebar
            collapsed={collapsed}
            position={position}
            onClick={this.handleSidebarClick}
          />

          <StyledMainSection>
            <ContentContainer
              position={position}
              collapsed={collapsed}
              onContentSelect={this.handleContentSelect}
              content={visibleContents}
              currentAsset={this.state.currentAsset}
            />
            <DiscoverMore href="prc.html">
              <span>Discover More</span>
              <DiscoverMoreIcon icon={faLongArrowAltRight} />
            </DiscoverMore>
          </StyledMainSection>
        </ContentPromoterContainer>
      </Fragment>
    );
  };
}

export default ContentPromoter;
