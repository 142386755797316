import React from "react";
import styled from "styled-components";
import { LABELS } from "./Utils"

const LabelBox = styled.div`
  position: absolute;
  display: ${({ name }) => (name ? "none" : "flex")};
  flex: 0 1 auto;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 67px;
  background-color: ${props => props.color}
  border-radius: 0 0 2px 0;
  box-shadow: 1px 1px 2px 0 rgba(0,0,0,0.1);
`;

const LabelText = styled.div`
  color: #FFFFFF;
  font-family: BrownPro;
  font-size: 10px;
  font-weight: bold;
  line-height: 13px;
  text-align: center;
`;
 
const Label = ({ name }) => {
  return (
    <LabelBox color={LABELS[name]}>
      <LabelText>{name}</LabelText>
    </LabelBox>
  );
};

export default Label;