import React, { Component } from "react";
import styled from "styled-components";

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
class Debug extends Component {
  constructor(args) {
    super(args);
    this.state = { position: args.position || "bottom" };
  }

  handleBottomClick = () => this.setState({ position: "bottom" });

  handleFloatClick = () => this.setState({ position: "float" });
  render = () => {
    if (!this.props.enabled) {
      return this.props.children({ position: this.state.position });
    }

    return (
      <Container>
        <Actions>
          <button onClick={this.handleBottomClick}>Bottom</button>
          <button onClick={this.handleFloatClick}>Float</button>
        </Actions>

        {this.props.children({ position: this.state.position })}
      </Container>
    );
  };
}
export default Debug;
