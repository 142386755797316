import React, { Component } from "react";
import ContentPromoter from "./components/ContentPromoter";
import Debug from "./components/Debug";
class App extends Component {
  render({ debugging } = this.props) {
    const { currentAsset, contents } = window.__DRAGONBOAT_PROTOTYPE__;

    return (
      <Debug position="float" enabled={debugging || false}>
        {({ position }) => <ContentPromoter
          position={position}
          currentAsset={currentAsset}
          contents={contents}
        />}
      </Debug>
    );
  }
}

export default App;
